import { InputAreaV2, Message, RadioButtonGroup, TextV2, Tile } from "@roole/components-library";
import { FC, useState } from "react";
import styles from "./DetailStep.module.scss";
import { useRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { formatToFrenchLongDate } from "utils/formatDate";
import { displayAssurancePrincipale } from "domain/service/Sinistre/useEvenement";
import { couvertureState } from "context/couvertureState/atom";
import { Couverture } from "domain/api-schemas";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";

export const DetailStep: FC<{ prevStep: Function }> = ({ prevStep }) => {
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [, setCouverture] = useRecoilState(couvertureState);
  const [showInfo, setShowInfo] = useState(false);

  const yesNo = [
    {
      label: "Oui",
      value: 1,
    },
    {
      label: "Non",
      value: 0,
    },
  ];

  const couvertures = [
    {
      label: "Tous risques",
      value: Couverture.TousRisques,
    },
    {
      label: "Au tiers",
      value: Couverture.AuTiers,
    },
  ];

  const handleChange = (e: any) => {
    setShowInfo(!Boolean(yesNo[e].value));
  };

  const handleChangeCouverture = (e: any) => {
    setCouverture(couvertures[e].value);
  };

  return (
    <>
      <Tile
        avatar={declarationData?.typeEvenement?.icone}
        icon="edit"
        label="Modifier"
        subtitle={declarationData?.typeEvenement?.nom || ""}
        title={
          declarationData?.dateEvenement
            ? `Sinistre du ${formatToFrenchLongDate(new Date(declarationData?.dateEvenement))}`
            : ""
        }
        onClick={() => prevStep()}
      />
      <div className={styles.content}>
        <TextV2 textVariant="body-small" color="secondary">
          Tous les champs sont obligatoires
        </TextV2>
        <div>
          <TextV2 textVariant="heading-2" as="h2" className={styles.title}>
            Que s'est-il passé ?
          </TextV2>
          <TextV2 textVariant="body-medium" as="p" className={styles.consignes}>
            Décrivez-nous précisément : quels sont les dégâts, l'origine, si vous êtes responsable,
            si un tiers est impliqué...
          </TextV2>
        </div>
        <Message
          icon="info"
          message={
            <>
              <TextV2 textVariant="body-small">
                <TextV2 textVariant="body-small-em">Par exemple · </TextV2>
                En rentrant chez moi hier, j'ai percuté le véhicule devant moi. Le conducteur n'est
                pas blessé. Mon pare-choc est abîmé.
              </TextV2>
            </>
          }
          type={"information"}
        />
        <InputAreaV2
          label={"Saisissez les détails de votre sinistre"}
          value={declarationData?.description ?? ""}
          onChange={(e) =>
            setDeclarationData({
              ...declarationData,
              description: e.target.value,
            } as DeclarationParams)
          }
          maxLength={1000}
        />
        <div className={styles.questions}>
          {declarationData?.typeEvenement?.garanties &&
            displayAssurancePrincipale(declarationData?.typeEvenement?.garanties) && (
              <div className={styles.radioDiv}>
                <TextV2 textVariant="heading-2" as="h2" extraCss={{ marginBottom: 16 }}>
                  Assurance principale
                </TextV2>
                <TextV2 textVariant="body-medium" as="p" className={styles.subtitle}>
                  Quelle est la couverture de votre assurance principale ?
                </TextV2>
                <RadioButtonGroup
                  direction="row"
                  elements={couvertures}
                  name="couverture"
                  onChange={handleChangeCouverture}
                  variant="tile"
                />
              </div>
            )}
          <div>
            <TextV2 textVariant="body-medium" as="p" className={styles.subtitle}>
              Avez-vous déclaré votre accident à votre assurance principale ?
            </TextV2>
            <RadioButtonGroup
              direction="row"
              elements={yesNo}
              name="aDeclare"
              onChange={handleChange}
              variant="tile"
            />
          </div>
        </div>
        {showInfo && (
          <div style={{ marginTop: 16 }}>
            <Message
              icon="info"
              message={
                <TextV2>
                  Pour être remboursé(e), vous aurez besoin de certaines pièces justificatives
                  obtenues auprès de votre assurance principale.
                </TextV2>
              }
              type={"information"}
            />
          </div>
        )}
      </div>
    </>
  );
};
