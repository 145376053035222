import React, { FC } from "react";
import { ReactComponent as File } from "images/icons/file.svg";
import { Document } from "domain/api-schemas";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import { useMatchMedia } from "hooks/useMatchMedia";

import cssVariables from "styles/variables.module.scss";
import styles from "./FileItem.module.scss";

interface FileItemProps {
  file: Document;
}

const FileItem: FC<FileItemProps> = ({ file }) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);

  return (
    <div key={file?.documentId} className={styles.item}>
      <Tooltip
        content={<p>{file?.nom}</p>}
        id={`id-${file?.documentId}`}
        anchorId={`id-${file?.documentId}`}
        events={["click"]}
        className={styles.item__tooltip}
        showIcon={isDesktop ? true : false}
      >
        <File />
        <p className={styles.item__text}>{file?.nom}</p>
      </Tooltip>
    </div>
  );
};
export default FileItem;
