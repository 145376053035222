import { FC, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { Text, OverlayLoader } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import { listMonitoredClaimsQuery } from "context/reportClaimState/selectors";
import { monContratState } from "context/currentContratState/atom";
import {
  reportClaimStepContextStateInitValue,
  reportClaimStepState,
} from "context/reportClaimState/atom";
import { LoadableState } from "context/state.types";
import { classNames } from "utils/classNames";
import { formatToFrenchDate } from "utils/formatDate";
import { HistoDemande } from "domain/api-schemas";
import { useMatchMedia } from "hooks/useMatchMedia";
import { RoutePaths } from "routes/routePaths";

import FileList from "components/molecules/FileList/FileList";
import { Card } from "components/atoms/Card/Card";
import { Box } from "components/atoms/Box/Box";
import { Title } from "components/atoms/Title/Title";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

import styles from "./Page.module.scss";
import cssVariables from "styles/variables.module.scss";

interface ObjectKeys {
  [key: string]: string;
}

const statusTypes: ObjectKeys = {
  Réglée: "accepted",
  "En attente de règlement": "waitingForPayment",
  "En cours": "inProgress",
  "Pièce(s) Manquante(s)": "missingDocuments",
  Refusée: "rejected",
};

const enCours = "En cours";
const pieceManquante = "Pièce(s) Manquante(s)";

const DisplayClaim = ({ claim, navigate }: { claim: HistoDemande; navigate: NavigateFunction }) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const setReportClaimStepContextState = useSetRecoilState(reportClaimStepState);
  const statusNotFinished =
    claim?.statutGarantieLibelle === pieceManquante || claim?.statutGarantieLibelle === enCours;

  return (
    <Card key={claim.demandeId} className={styles["claimMonitoring__Card"]}>
      <div
        className={classNames(styles["claimMonitoring__Wrapper"], styles["claimMonitoring__Grid"])}
      >
        <div className={styles["claimMonitoring__Grid--Left"]}>
          <Text as="h2" textVariant="heading-5">
            {claim?.libelleGarantie}
          </Text>
        </div>
        <div
          className={classNames(
            isDesktop && styles["claimMonitoring__Grid--End"],
            styles["claimMonitoring__Grid--Right"],
          )}
        >
          <div
            className={classNames(
              styles["claimMonitoring__Status"],
              "",
              styles[
                `claimMonitoring__Status--${
                  statusTypes[claim?.statutGarantieLibelle || ""]
                }` as keyof typeof styles
              ],
            )}
          >
            <Text as="p" textVariant="body">
              {claim?.statutGarantieLibelle}
            </Text>
          </div>
        </div>
        <div className={styles["claimMonitoring__Grid--Left"]}>
          <Text as="p" textVariant="body">
            <strong>
              {claim?.typeEvenement} le {formatToFrenchDate(claim?.dateSurvenance || null)}
            </strong>
          </Text>
          <Text as="p" textVariant="body" className={styles["claimMonitoring__ID"]}>
            Demande {claim?.numeroDemande}
          </Text>
          <br />
          <Text as="p" textVariant="body">
            Crée le {formatToFrenchDate(claim?.dateDemande || null)}
          </Text>
          <Text as="p" textVariant="body">
            <strong>Mise à jour le {formatToFrenchDate(claim?.dateModification || null)}</strong>
          </Text>
        </div>
        <div
          className={classNames(
            styles["claimMonitoring__Grid--Right"],
            styles["claimMonitoring__Grid--Half"],
          )}
        >
          <Text as="h2" textVariant="heading-6" className={styles["claimMonitoring__Subtitle"]}>
            Pièces justificatives
          </Text>
          {(claim?.statutGarantieLibelle === pieceManquante ||
            claim?.statutGarantieLibelle === "En cours") &&
          claim?.listeDocuments &&
          claim?.listeDocuments?.length > 0 ? (
            <FileList
              files={
                claim?.listeDocuments && claim?.listeDocuments?.length > 0
                  ? claim?.listeDocuments
                  : null
              }
              size={3}
            />
          ) : (
            <Text as="p" textVariant="body">
              Aucune pièce justificative n'a été transmise pour le moment
            </Text>
          )}
          {statusNotFinished && (
            <div className={classNames(styles["claimMonitoring__Button"])}>
              <Button
                variant="primary"
                size="small"
                onClick={async () => {
                  setReportClaimStepContextState({
                    ...reportClaimStepContextStateInitValue,
                    isEditing: true,
                    claimId: claim.demandeId || null,
                    activeStep: 4,
                    claimDate: !!claim.dateSurvenance ? new Date(claim.dateSurvenance) : null,
                    claimFiles:
                      claim.listeDocuments?.map((file) => {
                        return { name: file.nom || "" };
                      }) || null,
                  });
                  navigate(`${RoutePaths.DECLARER_SINISTRE_FILES}/${claim.demandeId}`);
                }}
              >
                Ajouter des justificatifs
              </Button>
            </div>
          )}
        </div>
      </div>
      {claim?.statutGarantieLibelle === "Refusée" && !!claim?.motifRefus && (
        <div className={styles["claimMonitoring__Grid--Full"]}>
          <Text as="p" textVariant="body">
            <strong>Motif Refus: {claim?.motifRefus}</strong>
          </Text>
        </div>
      )}
    </Card>
  );
};

export const Page: FC = () => {
  const monContrat = useRecoilValue(monContratState);
  const monitoredClaims = useRecoilValueLoadable(listMonitoredClaimsQuery);
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState("");
  const [passed, setPassed] = useState("");

  useEffect(() => {
    if (monitoredClaims.state === LoadableState.Has_Value) {
      setInProgress(
        monitoredClaims.contents?.reduce((filtered: any, claim: any) => {
          if (
            claim.statutGarantieLibelle === enCours ||
            claim.statutGarantieLibelle === pieceManquante
          ) {
            filtered.push(<DisplayClaim claim={claim} key={claim.demandeId} navigate={navigate} />);
          }
          return filtered;
        }, []),
      );
      setPassed(
        monitoredClaims.contents?.reduce((filtered: any, claim: any) => {
          if (
            claim.statutGarantieLibelle !== enCours &&
            claim.statutGarantieLibelle !== pieceManquante
          ) {
            filtered.push(<DisplayClaim claim={claim} key={claim.demandeId} navigate={navigate} />);
          }
          return filtered;
        }, []),
      );
    }
  }, [monitoredClaims.state]);

  return (
    <>
      {monitoredClaims.state === LoadableState.Has_Value ? (
        <>
          <div className={styles["claimMonitoring__TextIntro"]}>
            <Title as="h1" className={styles["claimMonitoring__Title"]}>
              Mes sinistres
            </Title>
            {monitoredClaims.contents && monitoredClaims.contents?.length > 0 && (
              <Button
                variant="primary"
                onClick={() => {
                  navigate(RoutePaths.DECLARER_SINISTRE_DATE);
                }}
                size="large"
              >
                <span id="declarer-sinistre-commencement">Déclarer un sinistre</span>
              </Button>
            )}
          </div>
          {monitoredClaims.contents === null && (
            <ErrorBlock
              phoneNumber={monContrat?.numeroTelephoneContact}
              variants={[ErrorBlockVariant.PhoneCall]}
            />
          )}
          {monitoredClaims.contents?.length === 0 ? (
            <div className={styles["claimMonitoring__Alert__Wrapper"]}>
              <Box className={styles["claimMonitoring__Alert"]}>
                <Text as="p" textVariant="heading-6">
                  Vous n'avez pas encore déclaré de sinistre
                </Text>
                <div className={styles["claimMonitoring__Alert__Button"]}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(RoutePaths.DECLARER_SINISTRE_DATE);
                    }}
                    size="large"
                  >
                    Déclarer un sinistre
                  </Button>
                </div>
              </Box>
            </div>
          ) : (
            <>
              <div className={styles["claimMonitoring__List"]}>
                {inProgress.length > 0 && (
                  <Text
                    as="h2"
                    textVariant="heading-5"
                    className={styles["claimMonitoring__SubtitleIntro"]}
                  >
                    En cours
                  </Text>
                )}
                {inProgress}
              </div>
              <div className={styles["claimMonitoring__List"]}>
                {passed.length > 0 && (
                  <Text
                    as="p"
                    textVariant="heading-5"
                    className={styles["claimMonitoring__SubtitleIntro"]}
                  >
                    Passés
                  </Text>
                )}
                {passed}
              </div>
            </>
          )}
        </>
      ) : (
        <OverlayLoader />
      )}
    </>
  );
};
