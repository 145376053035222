import { selector, selectorFamily } from "recoil";
import { monContratState } from "context/currentContratState/atom";
import { globalState } from "context/globalState/atom";
import { bonsPlansMobilitesApi } from "domain/api/BonPlansMobilites/bonsPlansMobilitesApi";
import { BonPlanCategory } from "domain/api-schemas";

export const bonPlansMobilitesQuery = selectorFamily({
  key: "bonPlansMobilitesQuery",
  get: (adhesionId: string | null) => async () => {
    if (!adhesionId) {
      return [];
    }

    const response = await bonsPlansMobilitesApi.fetchBonPlans(adhesionId);
    return response;
  },
});

export const listBonPlansMobilitesQuery = selector({
  key: "listBonPlansMobilitesQuery",
  get: ({ get }) => get(bonPlansMobilitesQuery(get(monContratState)?.idAdhesion || null)),
});

export const bonPlanByIdQuery = selectorFamily({
  key: "fetchBonPlanByIdQuery",
  get:
    (bonPlanId: string | null) =>
    async ({ get }) => {
      try {
        return await bonsPlansMobilitesApi.fetchBonPlanById(
          get(globalState).idAdhesion || null,
          bonPlanId,
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
});

export const bonPlanByCategoryIdQuery = selectorFamily({
  key: "fetchBonPlanByCategoryId",
  get:
    (categoryId: string | null) =>
    async ({ get }) => {
      try {
        if (categoryId === "0") {
          return [];
        }
        return await bonsPlansMobilitesApi.fetchBonPlanByCategoryId(
          get(globalState).idAdhesion || null,
          categoryId,
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
});

export const bonPlanCategoriesQuery = selector<BonPlanCategory[] | null>({
  key: "fetchCategories",
  get: async ({ get }): Promise<BonPlanCategory[] | null> => {
    try {
      return await bonsPlansMobilitesApi.fetchCategories(get(globalState).idAdhesion || null);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
});

export const bonPlanHomepageQuery = selector({
  key: "fetchHomepage",
  get: async ({ get }) => {
    try {
      return await bonsPlansMobilitesApi.fetchHomepage(get(globalState).idAdhesion || null);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
});
