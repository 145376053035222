import {
  AccordionV2,
  Divider,
  Drawer,
  DrawerMobile,
  IconV2,
  Message,
  Tag,
  TextV2,
  TileList,
} from "@roole/components-library";
import { GarantieAction, GarantieCategory, GarantieV2, MonContrat } from "domain/api-schemas";
import { FC, useEffect, useState } from "react";
import styles from "./GarantiesList.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useTracking } from "hooks/useTracking";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "routes/routePaths";
import cssVariables from "styles/variables.module.scss";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { appelerBtn, declarerBtn, numRoole } from "./Page";

export const GarantiesList: FC<{
  monContrat: MonContrat;
  garanties: GarantieCategory[];
}> = ({ monContrat, garanties }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [garantieSelected, setGarantieSelected] = useState<GarantieV2 | null>(null);
  const [labelButton, setLabelButton] = useState<string | undefined>("");

  const openDrawer = (garantie: GarantieV2) => {
    setIsOpen(!isOpen);
    setGarantieSelected({
      ...garantie,
      exclusions: garantie.exclusions?.slice().sort((a, b) => {
        if (a.titre && !b.titre) {
          return 1;
        } else if (!a.titre && b.titre) {
          return -1;
        } else {
          return 0;
        }
      }),
    });
  };
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  const drawerContent = garantieSelected && (
    <div style={{ display: "flex", flexDirection: "column", gap: 32, marginBottom: 40 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {garantieSelected.plafondMessage && (
          <TextV2 textVariant="body-large" color="positive">
            Plafond · {garantieSelected.plafondMessage}
          </TextV2>
        )}
        <span dangerouslySetInnerHTML={{ __html: garantieSelected.description }} />
        {garantieSelected.informationsUtiles && (
          <Message
            icon="info"
            message={
              <>
                <TextV2 textVariant="body-small-em">Informations utiles</TextV2>
                {garantieSelected.informationsUtiles.map((info, index) => (
                  <div
                    key={garantieSelected.crmId + index}
                    dangerouslySetInnerHTML={{ __html: info }}
                    style={{ fontSize: 13 }}
                  />
                ))}
              </>
            }
            type={"information"}
          ></Message>
        )}
      </div>
      {garantieSelected.evenements && (
        <div>
          <TextV2 textVariant="heading-3" as="h3">
            Votre garantie couvre :
          </TextV2>
          <div className={styles.evenementsGarantie}>
            {garantieSelected.evenements.map((evenement, index) => (
              <div className={styles.garantieElement}>
                <AccordionV2
                  key={evenement.code}
                  avatar={evenement.icone ?? "image"}
                  title={evenement.nom}
                  withDivider={index !== garantieSelected.evenements.length - 1}
                >
                  {evenement.plafondMessage && (
                    <TextV2 textVariant="body-medium-em" color="positive">
                      {evenement.plafondMessage}
                    </TextV2>
                  )}
                  <span dangerouslySetInnerHTML={{ __html: evenement.description }} />
                </AccordionV2>
              </div>
            ))}
          </div>
        </div>
      )}
      {garantieSelected.exclusions && (
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <TextV2 textVariant="heading-3" as="h3" style={{ marginBottom: 8 }}>
            Ce qui n'est pas couvert :
          </TextV2>
          {garantieSelected.exclusions.map((exclusion) => (
            <div key={exclusion.titre}>
              {exclusion.titre && <TextV2 textVariant="body-medium-em">{exclusion.titre} :</TextV2>}
              {exclusion.descriptions.map((description, index) => (
                <div key={exclusion.titre + index} className={styles.exclusion}>
                  <IconV2 name={"cross"} color="neutral" size={24} extraCss={{ marginTop: 1 }} />
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const handleClick = (isMobile = false) => {
    if (garantieSelected?.action === GarantieAction.Appeler) {
      sendToDataLayer("garantie-clic-telephone");
      setLabelButton(monContrat?.numeroTelephoneContact ?? numRoole);
      if (isMobile === true) {
        window.location.href = `tel:${formatPhoneNumber(
          monContrat?.numeroTelephoneContact ?? numRoole,
        )}`;
      }
    } else if (garantieSelected?.action === GarantieAction.Declarer) {
      sendToDataLayer("declarer-sinistre-commencement");
      navigate(RoutePaths.DECLARER_SINISTRE_DATE);
    }
  };

  let getLabelButton: string | undefined = undefined;

  if (garantieSelected?.action === GarantieAction.Appeler) {
    getLabelButton = appelerBtn;
  } else if (garantieSelected?.action === GarantieAction.Declarer) {
    getLabelButton = declarerBtn;
  }

  const handleClose = () => {
    setLabelButton(getLabelButton);
    setIsOpen(false);
  };

  useEffect(() => {
    setLabelButton(getLabelButton);
  }, [garantieSelected, getLabelButton]);

  return (
    <>
      {garanties?.map((garantieCategory) => (
        <div className={styles.card} key={garantieCategory.code}>
          <div className={styles.cardHeader}>
            <TextV2
              textVariant="heading-2"
              as="h2"
              color={!garantieCategory.isActive && "secondary"}
            >
              {garantieCategory.titre}
            </TextV2>
            {!garantieCategory.isActive && (
              <div className={styles.tag}>
                <Tag label="Inactif" type="neutral" />
              </div>
            )}
          </div>
          <div className={styles.garanties}>
            {garantieCategory.garanties.map((garantie, index) => (
              <div key={garantie.crmId}>
                <TileList
                  avatar={{
                    size: "L",
                    variant: garantieCategory.isActive ? "brand_light" : "neutral",
                    type: "icon",
                    icon: garantie.icone ?? "image",
                  }}
                  textRight={{
                    textVariant: "body-medium",
                    color: garantieCategory.isActive ? "secondary" : "disabled-light",
                    children: garantie.plafondMessage,
                  }}
                  chevronColor="neutral"
                  onClick={() => openDrawer(garantie)}
                >
                  <TextV2
                    textVariant="body-medium"
                    color={garantieCategory.isActive ? "dark" : "secondary"}
                  >
                    {garantie.nom}
                  </TextV2>
                </TileList>
                {index !== garantieCategory.garanties.length - 1 && <Divider variant="medium" />}
              </div>
            ))}
          </div>
        </div>
      ))}
      {garantieSelected &&
        (isMobile ? (
          <DrawerMobile
            isOpen={isOpen}
            handleClose={handleClose}
            title={garantieSelected.nom}
            content={drawerContent}
            labelPrimary={labelButton}
            onClickPrimary={() => handleClick(true)}
          />
        ) : (
          <Drawer
            title={garantieSelected.nom}
            isOpen={isOpen}
            handleClose={handleClose}
            content={drawerContent}
            labelPrimary={labelButton}
            onClickPrimary={handleClick}
          />
        ))}
    </>
  );
};

export default GarantiesList;
