import { FC } from "react";
import { useRecoilValue } from "recoil";
import { URLS } from "routes/urls";
import { RoutePaths } from "routes/routePaths";
import { monContratState } from "context/currentContratState/atom";
import { AlertRenew } from "components/atoms/Alert/AlertRenew";
import { Tuile } from "components/atoms/Tuile/Tuile";
import styles from "./Page.module.scss";
import useSmartPushFilters from "hooks/useSmartPush";
import { AlertImpaye } from "components/atoms/Alert/AlertImpaye";

import { useRecoilValueLoadable } from "recoil";
import { toggleFeatureQuery } from "context/selectors";
import { LoadableState } from "context/state.types";
import { FeaturesToggle } from "domain/api/useFeatureToggle";

const impaye = 7;

export const Page: FC = () => {
  const monContrat = useRecoilValue(monContratState);
  const codePack = ["RX", "OV", "OB", "WC", "WS", "WP", "WV", "WO", "WT", "WU", "WN"];
  const isInCodePack = codePack.includes(
    monContrat && monContrat.identifiantContrat ? monContrat.identifiantContrat.split("-")[0] : "",
  );

  const featureFlag = useRecoilValueLoadable(toggleFeatureQuery(FeaturesToggle.Sinistre));
  const isFeatureFlagged = featureFlag.state === LoadableState.Has_Value && featureFlag.contents;

  useSmartPushFilters([]);

  return (
    <section>
      {monContrat && (
        <>
          {monContrat?.statutContrat === impaye && <AlertImpaye />}
          <AlertRenew contract={monContrat} />
          <div className={styles.container}>
            {isInCodePack ? (
              <Tuile
                id="tuile-contact"
                titre="Besoin d’aide ?"
                url={URLS.CENTRE_AIDE}
                isExternalUrl
                titreLink="Nous contacter"
                className={styles.tuileAide}
              >
                Une équipe dédiée à votre service pour répondre à toutes vos questions en cas de
                pépin ou simplement autour de votre auto.
              </Tuile>
            ) : (
              <Tuile
                id="tuile-declarer-sinistre"
                titre="Déclarer un sinistre"
                url={isFeatureFlagged ? RoutePaths.DECLARER_SINISTRE_DATE : RoutePaths.SINISTRES}
                titreLink="Effectuer ma déclaration"
                className={styles.tuileAide}
              >
                En quelques clics, déclarez votre sinistre ou bénéficiez de vos garanties.
              </Tuile>
            )}
            <Tuile
              id="tuile-garanties"
              titre="Mes garanties"
              url={RoutePaths.GARANTIES}
              titreLink="En savoir plus"
            >
              L’ensemble des garanties et services inclus dans votre complémentaire auto.
            </Tuile>
            <Tuile
              id="tuile-bons-plans"
              titre="Mes bons plans mobilité et loisirs"
              url={RoutePaths.BON_PLANS_HOME}
              titreLink="En profiter"
            >
              Grâce à la force de notre communauté de plus d’1 million de membres, vous bénéficiez
              de services négociés au meilleur prix !
            </Tuile>

            <Tuile
              id="tuile-mon-garage"
              titre="Mon garage"
              url={RoutePaths.MON_COMPTE}
              titreLink="En profiter"
            >
              Retrouvez les infos de votre garage et bénéficiez des offres de notre réseau de
              partenaires, composé exclusivement de garages des réseaux constructeurs.
            </Tuile>
            <Tuile
              id="tuile-parrainage"
              titre="Parrainage"
              url={RoutePaths.PARRAINAGE}
              titreLink="Envoyer une invitation"
            >
              40 euros pour vous à chaque filleul devenant membre.
            </Tuile>
            <Tuile
              id="tuile-media"
              titre="Média"
              url={URLS.BLOG}
              isExternalUrl
              titreLink="Découvrir le média"
            >
              Des conseils pratiques autour de votre auto (réglementation, entretien, sur la
              route...) ainsi que nos actualités.
            </Tuile>
          </div>
        </>
      )}
    </section>
  );
};
