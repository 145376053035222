import { classNames } from "utils/classNames";
import styles from "./BonsPlansCategory.module.scss";
import { useNavigate } from "react-router-dom";
import { BonPlanCategory } from "domain/api-schemas";
import { useTracking } from "hooks/useTracking";
import { stripEmojis } from "utils/string";

interface BonsPlansCategoryProps {
  category: BonPlanCategory;
  currentSelected?: string;
}

const BonsPlansCategory: React.FC<BonsPlansCategoryProps> = ({ currentSelected, category }) => {
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();

  return (
    <button
      id={`bons-plans-${category.name}`}
      className={classNames(
        styles.BonsPlansCategory,
        currentSelected === category.id && styles["BonsPlansCategory_selected"],
      )}
      onClick={(e) => {
        sendToDataLayer(stripEmojis(e.currentTarget.id), false);
        navigate(`/bonplans/liste?category=${category.id}`);
      }}
    >
      {category.name}
    </button>
  );
};

export default BonsPlansCategory;
