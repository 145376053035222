import {
  ButtonV2,
  Divider,
  DrawerMobile,
  LoaderV2,
  Message,
  ModalV2,
  TextV2,
  TileList,
} from "@roole/components-library";
import { FC, Fragment, useState } from "react";
import styles from "./EventStep.module.scss";
import { listTypesEvenementsQuery } from "context/typeEvenementsState/selector";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { LoadableState } from "context/state.types";
import { declarationDataState } from "context/declarationState/atom";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { EvenementType } from "domain/api-schemas";
import { monContratState } from "context/currentContratState/atom";

export const EventStep: FC<{ nextStep: Function }> = ({ nextStep }) => {
  const [declarationData, setDeclarationData] = useRecoilState(declarationDataState);
  const [monContrat] = useRecoilState(monContratState);

  const typesEvents = useRecoilValueLoadable(
    listTypesEvenementsQuery({
      ageContrat: monContrat?.anciennete ?? 0,
      code: monContrat?.packServices.codePack ?? "",
      dateEvenement: declarationData?.dateEvenement ?? "",
    }),
  );
  const selectEvent = (typeEvent: EvenementType) => {
    setDeclarationData({
      ...declarationData,
      typeEvenement: typeEvent,
    } as DeclarationParams);
    nextStep();
  };
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  const modalContent = (
    <div>
      <TextV2 textVariant="body-medium" as="p">
        Vous rencontrez des difficultés pour qualifier votre sinistre ? Nos conseillers vous
        accompagnent dans votre démarche, du
        <TextV2 textVariant="body-medium-em"> lundi au vendredi, de 9h à 18h.</TextV2>
      </TextV2>
      <div className={styles.contactModalDiv}>
        <Message
          icon="phone"
          message={
            <TextV2>
              Contactez le <TextV2 textVariant="body-medium-em">01 46 90 37 00</TextV2>
            </TextV2>
          }
          type="information"
        />
      </div>
      <ButtonV2 variant="secondary-dark" onClick={() => setOpenModal(false)}>
        Retour à la déclaration
      </ButtonV2>
    </div>
  );

  return (
    <>
      <TextV2 textVariant="heading-2" as="h2">
        Que vous est-il arrivé ?
      </TextV2>
      <div className={styles.consignes}>
        <TextV2 textVariant="body-medium">Choisissez votre sinistre parmi la liste :</TextV2>
      </div>
      {typesEvents.state === LoadableState.Has_Value ? (
        <div className={styles.typesEventsList}>
          {typesEvents.contents.map((typeEvent) => (
            <Fragment key={typeEvent.code}>
              <TileList
                onClick={() => selectEvent(typeEvent)}
                mobileAlign="top"
                key={typeEvent.code}
                avatar={{
                  type: "icon",
                  icon: typeEvent.icone,
                  variant: "brand_light",
                  size: "L",
                }}
                children={
                  <div>
                    <TextV2 textVariant="body-medium-em">{typeEvent.nom}</TextV2>
                    <p
                      className={styles.eventDescription}
                      dangerouslySetInnerHTML={{ __html: typeEvent.description }}
                    />
                  </div>
                }
              />
              <Divider variant="light" />
            </Fragment>
          ))}
          <TileList
            onClick={() => setOpenModal(true)}
            mobileAlign="top"
            avatar={{
              type: "icon",
              icon: "help-circle",
              variant: "brand_light",
              size: "L",
            }}
            children={
              <div>
                <TextV2 textVariant="body-medium-em">Autre</TextV2>
                <p className={styles.eventDescription}>
                  Je ne trouve pas mon sinistre parmi les choix proposés.
                </p>
              </div>
            }
          />
        </div>
      ) : (
        <LoaderV2 />
      )}
      {isMobile ? (
        <DrawerMobile
          title={"Besoin d'aide ?"}
          content={modalContent}
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
        />
      ) : (
        <ModalV2
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          title={"Besoin d'aide ?"}
          content={modalContent}
        />
      )}
    </>
  );
};
