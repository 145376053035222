import { FC } from "react";
import { Link } from "react-router-dom";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useTracking } from "hooks/useTracking";
import { ReactComponent as LogoBig } from "images/logo/logo-big.svg";
import { ReactComponent as LogoBigTemp } from "images/logo/logo-big-temp.svg";
import { ReactComponent as LogoSmall } from "images/logo/logo-small.svg";
import { ReactComponent as LogoSmallTemp } from "images/logo/logo-small-temp.svg";
import cssVariables from "styles/variables.module.scss";

interface LogoProps {
  isTemp?: boolean;
  url: string;
  id?: string;
  isDisabledLink?: boolean;
}

const Logo: FC<LogoProps> = ({ isTemp = false, url, id, isDisabledLink }) => {
  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const { sendToDataLayer } = useTracking();
  const handleSendToAnalytics = () => (id ? sendToDataLayer(id) : () => undefined);
  return (
    <Link to={!isDisabledLink ? url : "#"}>
      <>
        {isTemp ? (
          <>
            {isDesktop ? (
              <LogoBigTemp onClick={handleSendToAnalytics} />
            ) : (
              <LogoSmallTemp onClick={handleSendToAnalytics} />
            )}
          </>
        ) : (
          <>
            {isDesktop ? (
              <LogoBig onClick={handleSendToAnalytics} />
            ) : (
              <LogoSmall onClick={handleSendToAnalytics} />
            )}
          </>
        )}
      </>
    </Link>
  );
};

export default Logo;
