import { FC } from "react";
import { Garantie } from "domain/api-schemas";
import { ListBox } from "components/atoms/ListBox/ListBox";
import GarantieLine from "../GarantiesLine/GarantiesLine";
import styles from "./GarantiesList.module.scss";

interface GroupedItemProps {
  categorie: Array<Garantie>;
  index: number;
  setItemAndAnalytics: (selectedCategorie: number, selectedGarantie: number) => void;
  statutMembre: string;
}

const GroupedItem: FC<GroupedItemProps> = ({
  categorie,
  index,
  setItemAndAnalytics,
  statutMembre,
}) => {
  const { libelleCategorie } = categorie[0] || {};

  return (
    <ListBox
      onInfo={(indexGarantie: number) => {
        setItemAndAnalytics(index, indexGarantie);
      }}
      className={styles.garantiesList}
      headLine={libelleCategorie.replace(/:/gi, "")}
      key={libelleCategorie}
      statutMembre={statutMembre}
    >
      {categorie.map(({ code, titre, plafondMaximum }: Garantie) => (
        <GarantieLine
          key={code}
          code={code}
          titre={titre}
          plafondMaximum={plafondMaximum}
          statutMembre={statutMembre}
        />
      ))}
    </ListBox>
  );
};

export default GroupedItem;
