import { Avatar, ButtonV2, DrawerMobile, ModalV2, Tag, TextV2 } from "@roole/components-library";
import { couvertureState } from "context/couvertureState/atom";
import { declarationDataState } from "context/declarationState/atom";
import { Couverture, GarantieV2 } from "domain/api-schemas";
import { FC, useState } from "react";
import { useRecoilState } from "recoil";
import styles from "./GarantiesStep.module.scss";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { classNames } from "utils/classNames";

export const GarantiesStep: FC = () => {
  const [couverture] = useRecoilState(couvertureState);
  const [declarationData] = useRecoilState(declarationDataState);
  const [openModal, setOpenModal] = useState(false);
  const [selectedGarantie, setSelectedGarantie] = useState<GarantieV2 | null>(null);
  const [garantiesEligibles, setGarantiesEligibles] = useState<string[]>([]);
  const [garantiesNonEligibles, setGarantiesNonEligibles] = useState<string[]>([]);

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  console.log(couverture && Couverture[couverture]);
  console.log(declarationData);

  const checkGarantie = (garantie: GarantieV2) => {
    setSelectedGarantie(garantie);
    setOpenModal(true);
  };

  const handleClose = () => {
    setSelectedGarantie(null);
    setOpenModal(false);
  };

  const checkEligible = () => {
    if (selectedGarantie) {
      const isEligible = false; // TODO: test éligibilité
      if (isEligible) {
        setGarantiesEligibles([...garantiesEligibles, selectedGarantie?.crmId]);
      } else {
        setGarantiesNonEligibles([...garantiesNonEligibles, selectedGarantie?.crmId]);
      }
      setOpenModal(false);
    }
  };

  const modalContent = (
    <div>
      <TextV2 textVariant="heading-3" as="h3">
        Complétez les informations
      </TextV2>
      <div className={styles.modalButtons}>
        <ButtonV2 variant="secondary-dark" onClick={handleClose}>
          Annuler
        </ButtonV2>
        <ButtonV2 variant="primary-brand" onClick={() => checkEligible()}>
          Vérifier mon éligibilité
        </ButtonV2>
      </div>
    </div>
  );

  return (
    <div className={styles.content}>
      <TextV2 textVariant="heading-2" as="h2">
        Vos garanties
      </TextV2>
      <TextV2>Vérifiez votre éligibilité aux garanties dont vous souhaitez bénéficier :</TextV2>
      <div className={styles.garanties}>
        {declarationData?.typeEvenement?.garanties?.map((garantie) => {
          const isEligible = garantiesEligibles.includes(garantie.crmId)
            ? true
            : garantiesNonEligibles.includes(garantie.crmId)
            ? false
            : undefined;
          return (
            <div
              key={garantie.crmId}
              className={classNames(
                styles.tileGarantie,
                isEligible === true && styles.eligible,
                isEligible === false && styles.nonEligible,
              )}
            >
              <div>
                <Avatar
                  type="icon"
                  icon={
                    isEligible === true
                      ? "shield-checked"
                      : isEligible === false
                      ? "shield-off"
                      : "shield"
                  }
                  size="L"
                  variant={isEligible === true ? "validation" : "neutral"}
                />
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className={classNames(
                    styles.tileGarantieContent,
                    isEligible === false && styles.nonEligible,
                  )}
                >
                  <div className={styles.tileGarantieTitle}>
                    <TextV2
                      textVariant="heading-4"
                      as="h4"
                      color={isEligible === false && "secondary"}
                    >
                      {garantie.nom}
                    </TextV2>
                    {isEligible !== undefined && (
                      <div className={styles.tileGarantieTag}>
                        <Tag
                          label={isEligible ? "Éligible" : "Non éligible"}
                          type={isEligible ? "validation" : "neutral"}
                        />
                      </div>
                    )}
                  </div>

                  <TextV2 textVariant="body-medium-em" color={isEligible === false && "secondary"}>
                    {garantie.plafondMessage}
                  </TextV2>
                  <p
                    className={classNames(isEligible === false && styles.nonEligible)}
                    dangerouslySetInnerHTML={{ __html: garantie.description }}
                  />
                </div>
                {isEligible === undefined ? (
                  <ButtonV2
                    variant="primary-brand"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Vérifier mon éligibilité
                  </ButtonV2>
                ) : (
                  <ButtonV2
                    variant="secondary-dark"
                    onClick={() => checkGarantie(garantie)}
                    size="small"
                  >
                    Modifier mes informations
                  </ButtonV2>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {isMobile ? (
        <DrawerMobile
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      ) : (
        <ModalV2
          title={selectedGarantie?.nom ?? ""}
          content={modalContent}
          isOpen={openModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};
