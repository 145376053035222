import { DossierGarantie, PjDescription } from "domain/api-schemas";

const dossierOldPrefix = "IDG";
const dateMes = new Date(2024, 9, 1);

export const matchPjDescription = (crmId: string, listPjDescriptions: PjDescription[] | null) => {
  return listPjDescriptions?.find(
    (pjInfo: PjDescription) => crmId.toLocaleLowerCase() === pjInfo.crmId.toLocaleLowerCase(),
  );
};

export const stripHtmlTags = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export interface UploadedFile {
  name: string;
  base64: string;
  size: number;
  date: string;
  typeId: string;
}

const isUploadedFile = (object: any): object is UploadedFile => {
  return "base64" in object;
};

export const handleViewDocument = (item: UploadedFile, isMobile: boolean) => {
  if (isUploadedFile(item)) {
    const base64Data = item.base64.replace(/"/g, "");
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: base64Data.split(";")[0].split(":")[1] });
    const url = URL.createObjectURL(blob);

    if (isMobile) {
      const a = document.createElement("a");
      a.href = url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.open(url, "_blank");
    }
  }
};

export const ibanFormat = (iban: string) => {
  return iban?.replace(/(.{4})/g, "$1 ");
};

export const checkDossierOld = (dossier: DossierGarantie | null) => {
  return (
    dossier?.nom.startsWith(dossierOldPrefix, 0) &&
    dossier?.piecesDossiers?.some((p) => p.typeId !== null && new Date(p.dateAjout) < dateMes)
  );
};

export const calculNbPjSentOld = (dossier: DossierGarantie, nbPiecesTotal: number) => {
  const nbPieceRecues = dossier.piecesDossiers?.filter((piece) => piece.typeId !== null).length;
  return nbPieceRecues > nbPiecesTotal ? nbPiecesTotal : nbPieceRecues;
};
