import { FC, useState } from "react";
import { Button } from "components/atoms/Button/Button";
import { Document } from "domain/api-schemas";
import FileItem from "components/atoms/FileItem/FileItem";
import styles from "./FileList.module.scss";

interface FileListProps {
  files: Document[] | null;
  size: number;
}

const FileList: FC<FileListProps> = ({ files, size }) => {
  const [showAll, setShowAll] = useState(false);
  if (files && files?.length < 1) {
    return null;
  }
  return (
    <>
      <div className={styles.list__wrapper}>
        {files?.slice(0, showAll ? undefined : size)?.map((file) => (
          <FileItem key={file.documentId} file={file} />
        ))}
      </div>
      {files && files?.length > size && (
        <div className={styles.list__button}>
          <Button variant="outlined-light" size="small" onClick={() => setShowAll(!showAll)}>
            {!showAll ? "Voir tous les justificatifs" : "Voir moins"}
          </Button>
        </div>
      )}
    </>
  );
};
export default FileList;
