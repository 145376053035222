import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { OverlayLoader, LoaderV2 } from "@roole/components-library";

import { RoutePaths } from "routes/routePaths";
import { navigationState } from "context/navigationState/atom";
import { LoadableState } from "context/state.types";
import { Page } from "./Page";
import { toggleFeatureQuery } from "context/selectors";
import { FeaturesToggle } from "domain/api/useFeatureToggle";

import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./Page.module.scss";

export const ClaimMonitoringGuard = () => {
  const { contents: navState, state } = useRecoilValueLoadable(navigationState);
  const [returnValue, setReturnValue] = useState<JSX.Element | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const featureFlag = useRecoilValueLoadable(toggleFeatureQuery(FeaturesToggle.Sinistre));

  useSmartPushFilters(["sinistre-4642"]);

  useEffect(() => {
    if (navState?.displayRoutes?.[RoutePaths.DECLARER_SINISTRE]) {
      setReturnValue(<Page />);
    } else {
      setReturnValue(<Navigate to={RoutePaths.CONTRAT_HOME} />);
    }
    if (state === LoadableState.Loading || featureFlag.state === LoadableState.Loading) {
      setReturnValue(<LoaderV2 />);
    } else if (featureFlag.state === LoadableState.Has_Value) {
      if (featureFlag.contents) {
        setReturnValue(<Page />);
      } else {
        setReturnValue(<Navigate to={RoutePaths.SINISTRES} />);
      }
    }
    setIsLoaded(true);
  }, [navState, state, featureFlag]);

  return isLoaded ? (
    returnValue
  ) : (
    <div className={styles.claimMonitoring__Loader}>
      <OverlayLoader />
    </div>
  );
};
