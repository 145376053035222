import { InputDate, TextV2 } from "@roole/components-library";
import { FC, useEffect, useState } from "react";
import styles from "./DateStep.module.scss";
import { useSetRecoilState } from "recoil";
import { declarationDataState } from "context/declarationState/atom";
import { DeclarationParams } from "domain/api/Sinistre/EvenementApi";
import { format } from "date-fns";

export const DateStep: FC = () => {
  const setDeclarationData = useSetRecoilState(declarationDataState);
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const dossier: DeclarationParams = {
      dateEvenement: format(value, "yyyy-MM-dd"),
    };
    setDeclarationData(dossier);
  }, [setDeclarationData, value]);

  return (
    <>
      <TextV2 textVariant="heading-2" as="h2">
        À quelle date ?
      </TextV2>
      <div className={styles.consignes}>
        <TextV2 textVariant="body-medium">Indiquez-nous la date de votre sinistre.</TextV2>
      </div>
      <InputDate
        label="Date du sinistre"
        helper="Format attendue : JJ/MM/AAAA"
        blockAfterDate={new Date()}
        value={value}
        setValue={setValue}
      />
    </>
  );
};
