import { FC, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { AlertRenew } from "components/atoms/Alert/AlertRenew";
import { ButtonV2, IconV2, OverlayLoader, TextV2 } from "@roole/components-library";
import { monContratState } from "context/currentContratState/atom";
import useSmartPushFilters from "hooks/useSmartPush";
import { useNavigate } from "react-router-dom";

import styles from "./Page.module.scss";
import { RoutePaths } from "routes/routePaths";
import { useTracking } from "hooks/useTracking";
import GarantiesList from "./GarantiesList";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { contratGarantieCategoriesQuery } from "context/garantiesState/selector";
import { LoadableState } from "context/state.types";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

export const appelerBtn = "Appeler Roole";
export const declarerBtn = "Déclarer un sinistre";
export const numRoole = "01 46 90 37 00";

export const Page: FC = () => {
  const monContrat = useRecoilValue(monContratState);
  const listeGarantiesCategories = useRecoilValueLoadable(contratGarantieCategoriesQuery);

  useSmartPushFilters(["garanties-4340"]);
  const navigate = useNavigate();
  const { sendToDataLayer } = useTracking();
  const [labelButton, setLabelButton] = useState(appelerBtn);

  const handleClick = () => {
    sendToDataLayer("garantie-clic-telephone");
    setLabelButton(monContrat?.numeroTelephoneContact ?? numRoole);
  };

  if (listeGarantiesCategories.state === LoadableState.Loading) {
    return <OverlayLoader />;
  }

  return (
    <>
      {monContrat &&
      listeGarantiesCategories.state === LoadableState.Has_Value &&
      listeGarantiesCategories.contents?.length ? (
        <div className={styles.mainContainer}>
          <AlertRenew contract={monContrat} />
          <div className={styles.headerContainer}>
            <TextV2 textVariant="heading-1" as="h1" className={styles.headerContainerTitle}>
              Mes garanties
            </TextV2>
            <ButtonV2
              size="small"
              onClick={() => {
                sendToDataLayer("declarer-sinistre-commencement");
                navigate(RoutePaths.DECLARER_SINISTRE_DATE);
              }}
              variant="primary-brand"
              icon="auto-shop"
              iconBeforeText={true}
            >
              Déclarer un sinistre
            </ButtonV2>
          </div>
          {monContrat.membre.statutMembre !== "Premium" && (
            <div className={styles.garantiesDisabled}>
              <TextV2 textVariant="heading-3" as="h3" className={styles.garantiesDisabledTitle}>
                Vos garanties sont désactivées
              </TextV2>
              <TextV2 textVariant="body-large">
                Suite à votre résiliation, vous êtes désormais membre Roole Free. Vous bénéficiez
                ainsi d'une offre restreinte et n'êtes plus couvert par vos garanties en cas de
                sinistres.
              </TextV2>
              <div className={styles.tileContact}>
                <div className={styles.tileContactLeft}>
                  <IconV2 name={"brake"} size={32} />
                  <div>
                    <TextV2 variant="body-medium">
                      Vous souhaitez réactiver l'ensemble des garanties et services de votre
                      complémentaire auto ? Contactez-nous gratuitement, nous nous occupons de tout
                      !
                    </TextV2>
                    <div className={styles.buttonCallMobile}>
                      <ButtonV2 variant="primary-dark" size="small" onClick={handleClick}>
                        <a
                          href={`tel:${formatPhoneNumber(
                            monContrat?.numeroTelephoneContact ?? "01 46 90 37 00",
                          )}`}
                        >
                          {labelButton}
                        </a>
                      </ButtonV2>
                    </div>
                  </div>
                </div>
                <div className={styles.buttonCall}>
                  <ButtonV2 variant="primary-dark" size="small" onClick={handleClick}>
                    {labelButton}
                  </ButtonV2>
                </div>
              </div>
            </div>
          )}
          <div className={styles.container}>
            <GarantiesList garanties={listeGarantiesCategories.contents!} monContrat={monContrat} />
          </div>
        </div>
      ) : (
        <ErrorBlock
          phoneNumber={monContrat?.numeroTelephoneContact}
          variants={[ErrorBlockVariant.Chat]}
        />
      )}
    </>
  );
};
