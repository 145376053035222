import axiosClient from "clientProvider/axiosConfig";
import { Evenement, EvenementType } from "domain/api-schemas";

export type TypesEvenementsParams = {
  ageContrat: number;
  code: string;
  dateEvenement: string;
};

export type DeclarationParams = {
  dateEvenement: string;
  typeEvenement?: EvenementType;
  description?: string;
};

export const evenementApi = {
  fetchEvenements: async (idAdhesion: string) => {
    const result = await axiosClient.get<Evenement[]>(`Evenement/Membre/${idAdhesion}`);
    return result.data;
  },

  fetchTypesEvenements: async (params: TypesEvenementsParams) => {
    const result = await axiosClient.get<EvenementType[]>(`Evenement/Types/Produit/`, {
      params,
    });
    return result.data;
  },
};
