import { FC, useState } from "react";
import { DossierGarantie, StatutDossierGarantie } from "domain/api-schemas";
import { Content } from "./Content";
import { Tile } from "./Tile";
import { EtudeContent } from "./EtudeContent";
import { RefuseContent } from "./RefuseContent";
import { NonConforme } from "./NonConforme";
import { Remboursement } from "./Remboursement";
import styles from "./TileContent.module.scss";
import { ButtonV2, DrawerMobile, IconV2, ModalV2, TextV2 } from "@roole/components-library";
import { currentContratQuery } from "context/currentContratState/selector";
import { useRecoilValue } from "recoil";
import { getShortMaskedIban } from "utils/MaskedIban";
import { ibanFormat } from "domain/service/Sinistre/useDossier";
import { useMatchMedia } from "hooks/useMatchMedia";
import cssVariables from "styles/variables.module.scss";

interface TileContentProps {
  dossierData: DossierGarantie;
  isDossierOld: boolean;
}

export const TileContent: FC<TileContentProps> = ({ dossierData, isDossierOld }) => {
  let content;
  const currentContratState = useRecoilValue(currentContratQuery);
  const { membre } = currentContratState ?? {};
  let displayModeContent = false;
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMatchMedia(cssVariables.breakpointMobile);
  const address = membre?.rue + ", " + membre?.ville + " " + membre?.codePostal;

  const modalContent = (
    <div>
      {dossierData.modeReglement && dossierData.modeReglement.valeur && (
        <TextV2 as="p" className={styles.modalContent}>
          {dossierData.modeReglement.valeur === "Virement"
            ? "Vous serez remboursé sur l'IBAN enregistré sur votre contrat et sur lequel sont prélevées vos échéances."
            : "Votre chèque sera envoyé à l'adresse enregistrée sur votre contrat."}
        </TextV2>
      )}
      <ButtonV2 onClick={() => setOpenModal(false)} variant="primary-brand">
        J'ai compris
      </ButtonV2>
    </div>
  );

  const modalTitle =
    dossierData.modeReglement && dossierData.modeReglement.valeur
      ? dossierData.modeReglement.valeur === "Virement"
        ? "Remboursement par virement bancaire"
        : "Remboursement par chèque"
      : "";

  const modeContent =
    dossierData.modeReglement && dossierData.modeReglement.valeur ? (
      <div className={styles.modeContent}>
        <IconV2 name={"rachat-de-franchise"} />
        <div>
          <div style={{ display: "flex", gap: 4 }}>
            <TextV2 textVariant="body-medium">Mode de remboursement</TextV2>
            <IconV2
              name={"help-circle"}
              onClick={() => setOpenModal(true)}
              extraCss={{ cursor: "pointer" }}
            />
          </div>
          <TextV2 textVariant="body-medium" color="secondary">
            {dossierData.modeReglement.valeur === "Virement"
              ? `Virement bancaire · ${ibanFormat(
                  getShortMaskedIban(dossierData.coordonneesBancaires?.iban || ""),
                )}`
              : `Chèque · ${address
                  .toLocaleLowerCase()
                  .replace(/(^\w{1})|([\s-]+\w{1})/g, (letter) => letter.toUpperCase())} `}
          </TextV2>
        </div>

        {isMobile ? (
          <DrawerMobile
            isOpen={openModal}
            title={modalTitle}
            content={modalContent}
            handleClose={() => setOpenModal(false)}
          />
        ) : (
          <ModalV2
            isOpen={openModal}
            title={modalTitle}
            content={modalContent}
            handleClose={() => setOpenModal(false)}
          />
        )}
      </div>
    ) : (
      <></>
    );

  switch (dossierData.statut.code) {
    case StatutDossierGarantie.Incomplet:
      content = <NonConforme dossierData={dossierData} />;
      displayModeContent = true;
      break;
    case StatutDossierGarantie.EnCours:
      content = <Content dossierData={dossierData} isDossierOld={isDossierOld} />;
      displayModeContent = true;
      break;
    case StatutDossierGarantie.EnEtude:
      content = <EtudeContent />;
      displayModeContent = true;
      break;
    case StatutDossierGarantie.Refuse:
      content = <RefuseContent motifRefus={dossierData.motifRefus.nom} />;
      displayModeContent = true;
      break;
    case StatutDossierGarantie.Accorde:
      content = <Remboursement dossierData={dossierData} />;
      break;
    default:
      content = null;
      break;
  }

  return (
    <div className={styles.tileContent}>
      <Tile dossierData={dossierData} />
      {displayModeContent && modeContent}
      <div className={styles.content}>{content}</div>
    </div>
  );
};
