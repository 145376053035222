import { FC, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";

import { RoutePaths } from "routes/routePaths";
import { EndPathLayout } from "components/molecules/Layout/EndPathLayout";
import {
  reportClaimStepContextStateInitValue,
  reportClaimStepState,
} from "context/reportClaimState/atom";
import { useRefreshSelector } from "hooks/useRefreshSelector";
import { listMonitoredClaimsQuery } from "context/reportClaimState/selectors";
import { ReportClaimErrorMessages } from "./ReportClaimErrorMessages";

import { useRecoilValueLoadable } from "recoil";
import { toggleFeatureQuery } from "context/selectors";
import { LoadableState } from "context/state.types";
import { FeaturesToggle } from "domain/api/useFeatureToggle";

import styles from "./ReportClaimSuccessStep.module.scss";

const MainText: FC<{ errors?: string[]; isClaimSuccess: boolean }> = ({
  errors,
  isClaimSuccess,
}) => (
  <>
    <ReportClaimErrorMessages errors={errors} />
    {!!errors && errors.length > 0 ? (
      <p className={styles.reportClaimSuccessStep__MainParagraph}>
        <strong>
          Attention dossier incomplet !<br />
          Votre demande a bien été prise en compte mais des pièces justificatives n’ont pas pu être
          envoyées.
        </strong>
        <br />
        Pour finaliser votre demande, veuillez-envoyer vos pièces justificatives à l’adresse
        suivante :{" "}
        <a className={styles.reportClaimSuccessStep__Link} href="mailto: indemnisation@roole.fr">
          indemnisation@roole.fr
        </a>{" "}
        en indiquant votre numéro de contrat disponible ici:&nbsp;
        <Link to={RoutePaths.CONTRAT} className={styles.reportClaimSuccessStep__Link}>
          <strong>Mon contrat</strong>
        </Link>
      </p>
    ) : (
      <p className={styles.reportClaimSuccessStep__MainParagraph}>
        {isClaimSuccess
          ? "Votre déclaration a bien été prise en compte. Le temps de traitement minimum par notre relation client est de 2 jours ouvrés. Merci de votre compréhension."
          : "Vos pièces justificatives ont bien été prises en compte. Le temps de traitement minimum par notre relation client est de 2 jours ouvrés. Merci de votre compréhension."}
      </p>
    )}
    <p>A très bientôt !</p>
    <p>L'équipe Roole</p>
  </>
);

const ReportClaimStep: FC<{ isClaimSuccess: boolean }> = ({ isClaimSuccess }) => {
  const [{ errors }, setReportClaimStepContextState] = useRecoilState(reportClaimStepState);
  const [claimErrors] = useState<string[]>(errors?.claimPiecesJointes ?? []);
  useRefreshSelector(listMonitoredClaimsQuery);

  const featureFlag = useRecoilValueLoadable(toggleFeatureQuery(FeaturesToggle.Sinistre));
  const isFeatureFlagged = featureFlag.state === LoadableState.Has_Value && featureFlag.contents;

  useEffect(() => {
    setReportClaimStepContextState({ ...reportClaimStepContextStateInitValue, activeStep: 5 });
  }, []);

  const subTitle = isClaimSuccess
    ? "Votre déclaration a bien été envoyée"
    : "Votre déclaration a été mise à jour";
  const stepId = isClaimSuccess
    ? "declarer-sinistre-success"
    : "declaration-sinistre-success-after-update";

  return (
    <EndPathLayout
      headerTitle="Déclaration de sinistre"
      subTitle={subTitle}
      mainText={<MainText errors={claimErrors} isClaimSuccess={isClaimSuccess} />}
      id={stepId}
      redirectionUrl={isFeatureFlagged ? RoutePaths.MES_SINISTRES : RoutePaths.SINISTRES}
      buttonText="Suivre mes sinistres"
      closeButtonId="declaration-sinistre-croix-quitter"
    />
  );
};

export const ReportClaimSuccessStep: FC = () => <ReportClaimStep isClaimSuccess={true} />;
export const ReportClaimAttachmentStep: FC = () => <ReportClaimStep isClaimSuccess={false} />;
