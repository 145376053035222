import { FC } from "react";
import styles from "./DeclarationStep.module.scss";
import { DateStep } from "./DeclarationStep/DateStep";
import { EventStep } from "./DeclarationStep/EventStep";
import { DetailStep } from "./DeclarationStep/DetailStep";

export const DATE_STEP = 0;
export const EVENT_STEP = 1;
export const DETAIL_STEP = 2;

export const DeclarationStep: FC<{
  currentStep: number;
  nextStep: Function;
  prevStep: Function;
}> = ({ currentStep, nextStep, prevStep }) => {
  let content;

  switch (currentStep) {
    case DATE_STEP:
      content = <DateStep />;
      break;
    case EVENT_STEP:
      content = <EventStep nextStep={nextStep} />;
      break;
    case DETAIL_STEP:
      content = <DetailStep prevStep={prevStep} />;
      break;
    default:
      content = null;
      break;
  }

  return <div className={styles.content}>{content}</div>;
};
