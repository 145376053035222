import { FC, useCallback, useEffect, useState } from "react";
import styles from "./Page.module.scss";
import {
  StepperDropdown,
  Stepper,
  ProgressStep,
  StepperItemProps,
  StepperDropdownItemProps,
  ButtonV2,
} from "@roole/components-library";
import cssVariables from "styles/variables.module.scss";
import { useMatchMedia } from "hooks/useMatchMedia";
import { currentContratQuery } from "context/currentContratState/selector";
import { tunnelLayoutState, defaultStateValues } from "context/tunnelLayoutState/atom";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { Footer } from "components/molecules/Footer/Footer";
import { DeclarationStep, EVENT_STEP } from "./DeclarationStep";
import { ButtonGroup } from "@roole/components-library";
import { GarantiesStep } from "./GarantiesStep";

export const Page: FC = () => {
  const contrat = useRecoilValueLoadable(currentContratQuery);

  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const setupLayoutState = (setLayoutState: (state: any) => void, handleClose: () => void) => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Déclarer un sinistre",
      closeButtonId: "declarer-sinistre-croix",
      onCloseCallback: handleClose,
    });
  };
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(RoutePaths.SINISTRES);
  }, [navigate]);

  useEffect(() => {
    setupLayoutState(setLayoutState, handleClose);
  }, [setLayoutState, handleClose]);

  const isMobileOrTablet = useMatchMedia(cssVariables.mobile_and_tablet);

  const [currentStep, setCurrentStep] = useState(1 / 3);
  const [currentSubStep, setCurrentSubStep] = useState(0);

  const stepperSteps: StepperItemProps[] = [
    {
      badge: 1,
      label: "Déclaration du sinistre",
      description: `En cours`,
      type: "active",
    },
    {
      badge: 2,
      label: "Complétion du dossier",
      description: "Vous ajoutez toutes les pièces justificatives obligatoires",
      type: "default",
    },
    {
      badge: 3,
      label: "Étude du dossier",
      description: "Nous vérifions les pièces justificatives dans les meilleurs délais",
      type: "default",
    },
    {
      badge: 4,
      label: "Conclusion du dossier",
      description: "Nous apportons la réponse à votre dossier",
      type: "default",
    },
  ];

  const transformedSteps: StepperDropdownItemProps[] = stepperSteps.map((step) => ({
    ...step,
    type: step.type === "filled" || step.type === "active" ? "active" : "default",
  }));

  const nextStep = () => {
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1 / 3);
      setCurrentSubStep(currentSubStep + 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep <= 1) {
      setCurrentStep(currentStep - 1 / 3);
      setCurrentSubStep(currentSubStep - 1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    "Détail du sinistre",
    "Garanties",
    "Pièces justificatives",
    "Coordonnées bancaires",
    "Récapitulatif",
  ];

  const stepsContent = [
    "",
    <DeclarationStep currentStep={currentSubStep} nextStep={nextStep} prevStep={prevStep} />,
    <GarantiesStep />,
    "PJ",
    "IBan",
    "Recap",
  ];

  const isMobile = useMatchMedia(cssVariables.breakpointMobile);

  useEffect(() => {
    if (currentStep < 1 / 3) {
      navigate(RoutePaths.DECLARER_SINISTRES);
    }
  }, [currentStep]);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.stepper}>
          {isMobileOrTablet ? (
            <StepperDropdown steps={transformedSteps} defaultValue={0} />
          ) : (
            <Stepper steps={stepperSteps} />
          )}
        </div>
        <div className={styles.container}>
          <ProgressStep steps={steps} currentStep={currentStep} />
          {stepsContent[Math.ceil(currentStep)]}
          <div className={styles.footer}>
            {currentSubStep === EVENT_STEP ? (
              <ButtonV2 variant="secondary-dark" onClick={prevStep}>
                Retour
              </ButtonV2>
            ) : (
              <ButtonGroup
                size="large"
                direction={isMobile ? "column" : "row"}
                buttonPrimary={{
                  children: "Continuer",
                  variant: "primary-brand",
                  onClick: nextStep,
                }}
                buttonSecondary={{
                  children: "Retour",
                  variant: "secondary-dark",
                  onClick: prevStep,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Footer contrat={contrat?.contents || null} idAdhesion={null} />
    </>
  );
};
