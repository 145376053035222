export enum RoutePaths {
  HOME = "/index",
  AIDE = "/aide",
  PARRAINAGE = "/parrainage",
  RESILIATION = "/Resiliation/Resilier",
  RECONDUCTION_ROUTE = "/reconduction",
  RECONDUCTION_HOME = "/reconduction/mesgaranties",
  RECONDUCTION_IBAN = "/reconduction/mesinfospaiement",
  RECONDUCTION_INFOPERSO = "/reconduction/mesinfospersonnelles",
  RECONDUCTION_SIGNATURE = "/reconduction/signature",
  RECONDUCTION_RESULT_SUCCESS = "/reconduction/confirmationSuccess",
  RECONDUCTION_RESULT_CANCELED = "/reconduction/confirmationCancel",
  RECONDUCTION_CONFIRMATION_CB = "/reconduction/confirmation-cb",
  GARANTIES = "/mesgaranties/Liste",
  CONTRAT = "/contrat/detail",
  CONTRAT_CHOISIR = "/contrat/changer",
  CONTRAT_HOME = "/contrat/accueil",
  CONTRAT_AJOUTER = "/contrat/ajout",
  BON_PLANS_HOME = "/bonplans/liste",
  BON_PLANS = "/bonplans/bonplan",
  BON_PLANS_DETAILS = "/bonplans/details",
  MON_COMPTE = "/mon-garage",
  DECLARER_SINISTRE = "/messinistres/declarer",
  DECLARER_SINISTRE_DATE = "/messinistres/declarer/date",
  DECLARER_SINISTRE_TYPE = "/messinistres/declarer/type",
  DECLARER_SINISTRE_GUARANTEES = "/messinistres/declarer/garanties",
  DECLARER_SINISTRE_FILES = "/messinistres/declarer/fichiers",
  DECLARER_SINISTRE_SUCCESS = "/messinistres/declarer/confirmation",
  DECLARER_SINISTRE_ATTACHMENT = "/messinistres/declarer/envoyer",
  DECLARER_SINISTRE_ERROR = "/messinistres/declarer/echec",
  MES_SINISTRES = "/messinistres",
  CONTACT = "/contact",
  CONTACT_REASON = "/contact/motif",
  CONTACT_TYPE = "/contact/canal",
  CONTACT_TELEPHONE = "/contact/telephone",
  CONTACT_MAIL = "/contact/mail",
  CONTACT_CONFIRMATION = "/contact/confirmation",
  CONTACT_SUCCESS = "/contact/succes",
  CONTACT_FAILURE = "/contact/echec",
  USER_PROFIL = "/userprofil",
  REGULARISATION = "/regulariser",
  SINISTRES = "/sinistres",
  DETAIL_SINISTRE = "/sinistres/detail/dossier",
  DECLARER_SINISTRES = "/sinistres/declarer",
  DECLARER_SINISTRES_COMMENCER = "/sinistres/declarer/etape-1",
}

export enum PublicRoutePaths {
  Error = "/error",
  SIGNIN = "/signin",
  RECONDUCTION_CRM_OK = "/reconduction/validation",
  RECONDUCTION_CRM_KO = "/reconduction/refus",
  BONPLAN_VINCI = "/bonplan/vinci",
  CONFIRMER_RESILIATION = "/confirmer-resiliation",
  CONFIRMER_RESILIATION_ERROR = "/echec",
  PAIEMENT_SUCCESS = "/bonplans/paiement/confirmation",
  PAIEMENT_ABANDON = "/bonplans/paiement/abandon",
  PAIEMENT_ERROR = "/bonplans/paiement/echec",
  PAIEMENT_RESULT = "/bonplans/paiement/result",
}
