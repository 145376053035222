import { HTMLAttributes, useEffect, useState, FC } from "react";
import { useRecoilValueLoadable } from "recoil";

import { navigationState } from "context/navigationState/atom";
import { LoadableState } from "context/state.types";
import { MonContrat } from "domain/api-schemas";
import { classNames } from "utils/classNames";
import { NavSidebar } from "./NavSidebar";

import styles from "./NavSidebarMobile.module.scss";

type NavSideBarMobileProps = {
  isOpen: boolean;
  onClose: () => void;
  contract?: MonContrat;
} & HTMLAttributes<HTMLDivElement>;

export const NavSidebarMobile: FC<NavSideBarMobileProps> = ({ isOpen, onClose, className }) => {
  const [isDisplayed, setIsDisplayed] = useState(isOpen);
  const navigationStateValue = useRecoilValueLoadable(navigationState);
  const loading = navigationStateValue.state === LoadableState.Loading;

  useEffect(() => {
    setIsDisplayed(isOpen);

    if (isOpen) {
      document.body.classList.add("nav-open");
    } else {
      document.body.classList.remove("nav-open");
    }
    return () => {
      document.body.classList.remove("nav-open");
    };
  }, [isOpen]);

  return (
    <>
      {isDisplayed && (
        <div className={styles.overlay}>
          <div className={classNames(className, styles.container)}>
            <NavSidebar
              displayRoutes={!loading ? navigationStateValue.contents?.displayRoutes : {}}
              onClose={onClose}
            />
          </div>
        </div>
      )}
    </>
  );
};
