import { FC, useEffect, useState, useRef } from "react";
import useSmartPushFilters from "hooks/useSmartPush";
import styles from "./BonPlan.module.scss";
import { useHealthCheckContext } from "HealthCheckProvider";
import { HealthCheckGroups } from "SignalRModels";
import { classNames } from "utils/classNames";
import { OverlayLoader, Text } from "@roole/components-library";
import { Button } from "components/atoms/Button/Button";
import Banniere from "./components/Banniere/Banniere";
import BonPlanCard from "components/atoms/BonPlanCard/BonPlanCard";
import { ReactComponent as ChevronBack } from "images/icons/chevronBack.svg";
import useMediaQuery from "hooks/useMediaQuery";
import { DropDown } from "components/atoms/DropDown/DropDown";
import { MemberReview } from "components/molecules/MemberReview/MemberReview";
import { ExternalLink } from "components/atoms/ExternalLink/ExternalLink";
import { Title } from "components/atoms/Title/Title";
import CarousselWithLeftRightArrows from "components/molecules/CarousselWithLeftRightArrows/CarousselWithLeftRightArrows";
import { bonPlanByIdQuery } from "context/bonPlansMobilitesState/selector";
import { useSearchParams } from "react-router-dom";
import { LoadableState } from "context/state.types";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { BonPlanMobilite } from "domain/api-schemas";

import { useTracking } from "hooks/useTracking";
import { openWindowNoCache } from "utils/openWindowNoCache";
import { bonsPlansMobilitesApi } from "domain/api/BonPlansMobilites/bonsPlansMobilitesApi";
import { globalState } from "context/globalState/atom";
import { formatNumberDigits } from "utils/formatNumberDigits";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

const BonPlan: FC = () => {
  const [params] = useSearchParams();
  const idParam = params.get("id");

  const { sendToDataLayer } = useTracking();

  const { idAdhesion } = useRecoilValue(globalState);

  const bonPlanState = useRecoilValueLoadable(bonPlanByIdQuery(idParam));
  const bonPlan: BonPlanMobilite | null =
    bonPlanState.state === LoadableState.Has_Value ? bonPlanState.contents : null;

  // replace "_" with "disableGroup"
  const [disableGroup, setDisableGroup] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [bonPlanIndex, setBonPlanIndex] = useState(0);
  const { getHealthCheckByGroupName } = useHealthCheckContext();
  const isMobile = useMediaQuery("mobile");
  const illustrationsRef = useRef<HTMLDivElement | null>(null);
  const avisRef = useRef<HTMLDivElement | null>(null);

  useSmartPushFilters(["bons-plans-4341"]);

  useEffect(() => {
    setDisableGroup(getHealthCheckByGroupName(HealthCheckGroups.BonPLanNationaux));
  }, [getHealthCheckByGroupName]);

  const idPracticalInformations = [
    "",
    "bons-plans-conditons-offre",
    "bons-plans-questions-frequentes",
    "bons-plans-mentions-legales",
  ];

  const stateHasError = bonPlanState.state === LoadableState.Has_Error;
  const isLoading = bonPlanState.state === LoadableState.Loading;
  return (
    <>
      {disableGroup || stateHasError ? (
        <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
      ) : (
        <>
          {isLoading ? (
            <OverlayLoader />
          ) : (
            bonPlan && (
              <div className={styles.BonPlanCategory}>
                <Banniere bonPlan={bonPlan} bonPlanIndex={bonPlanIndex} />

                <div className={styles.bonsPlanContentWrapper}>
                  <div className={styles.detailsBonsPlans}>
                    <Text textVariant="heading-6" as="h6">
                      Description du partenaire
                    </Text>

                    <Text
                      textVariant="body"
                      as="p"
                      dangerouslySetInnerHTML={{ __html: bonPlan.partner?.text }}
                    />

                    {bonPlan.subPlans && bonPlan.subPlans.length > 1 ? (
                      <div className={styles.bonsPlansTabs}>
                        {bonPlan.subPlans?.map((subPlan, index) => {
                          const isCurrentBonPlan = bonPlanIndex === index;

                          return (
                            <Text
                              id={`bons-plans-offre2-${bonPlan.partner?.name}`}
                              onClick={() => {
                                sendToDataLayer(`bons-plans-offre2-${bonPlan.partner?.name}`, true);
                                setBonPlanIndex(index);
                                if (isMobile) {
                                  setDropDownOpen(!dropDownOpen);
                                }
                              }}
                              textVariant="subheading-em" //{isCurrentBonPlan ? "subheading-em" : "subheading"}
                              as="h3"
                              className={classNames(
                                isCurrentBonPlan && styles.bonPlanTabSelected,
                                !isCurrentBonPlan && styles.bonPlanTab,
                                !isCurrentBonPlan && dropDownOpen && styles.bonPlanTab_droppedDown,
                              )}
                            >
                              {subPlan.title}
                              {isCurrentBonPlan && (
                                <ChevronBack
                                  className={classNames(
                                    styles.dropDownIcon,
                                    dropDownOpen
                                      ? styles.dropDownIcon_open
                                      : styles.dropDownIcon_closed,
                                  )}
                                />
                              )}
                            </Text>
                          );
                        })}
                      </div>
                    ) : (
                      <Text textVariant="heading-6" as="h6">
                        {bonPlan.subPlans && bonPlan.subPlans[0] && bonPlan.subPlans[0].title}
                      </Text>
                    )}

                    {bonPlan.subPlans &&
                      bonPlan.subPlans[bonPlanIndex].descriptions?.map((desc) => (
                        <div className={styles.bonPlanDescription}>
                          {desc.title && (
                            <Text textVariant="body-em" as="h6">
                              {desc.title}
                            </Text>
                          )}
                          <Text
                            textVariant="body"
                            as="p"
                            dangerouslySetInnerHTML={{ __html: desc.text }}
                          />
                        </div>
                      ))}

                    <Text textVariant="heading-6" as="h6">
                      {bonPlan.subPlans && bonPlan.subPlans[bonPlanIndex].howToUse?.title}
                    </Text>

                    {bonPlan.subPlans &&
                      bonPlan.subPlans[bonPlanIndex].howToUse?.descriptions?.map(
                        ({ title, text }) => (
                          <div className={styles.bonPlanExplicationText}>
                            {title && (
                              <Text textVariant="body-em" as="h6">
                                {title}
                              </Text>
                            )}
                            <Text
                              textVariant="body"
                              as="p"
                              dangerouslySetInnerHTML={{ __html: text }}
                            />
                          </div>
                        ),
                      )}

                    {bonPlan.viewCount && bonPlan.viewCount >= 10 && (
                      <Text className={styles.membersCount} as="p">
                        {"Ce bon plan a été vu " + formatNumberDigits(bonPlan.viewCount) + " fois"}
                      </Text>
                    )}

                    <div className={styles.bonsPlansActions}>
                      {(bonPlan.hasCustomLink ||
                        (bonPlan.subPlans && bonPlan.subPlans[bonPlanIndex].ctaLink)) &&
                        (bonPlan.customLink?.hasError ? (
                          <Text as="p">{bonPlan.customLink?.errorMessage}</Text>
                        ) : (
                          <Button
                            id={`bons-plans-JenProfite2-${bonPlan.partner?.name}`}
                            variant="primary"
                            className={styles.enProfiter}
                            onClick={async (e) => {
                              sendToDataLayer(e.currentTarget.id, false);
                              if (bonPlan.hasCustomLink) {
                                const link = await bonsPlansMobilitesApi.generateCustomLink(
                                  bonPlan.id!,
                                  idAdhesion,
                                );
                                link && openWindowNoCache(link.link!);
                              } else {
                                bonPlan.subPlans &&
                                  bonPlan.subPlans[0].ctaLink &&
                                  openWindowNoCache(bonPlan.subPlans[0].ctaLink);
                              }
                            }}
                          >
                            <b>En profiter</b>
                          </Button>
                        ))}
                      {bonPlan.subPlans && bonPlan.subPlans[bonPlanIndex].ctaMap && (
                        <Button
                          id={`bons-plans-carte-${bonPlan.partner?.name}`}
                          variant="outlined-light"
                          className={styles.voirCarte}
                          onClick={(e) => {
                            sendToDataLayer(e.currentTarget.id, false);
                          }}
                        >
                          <ExternalLink
                            icon={false}
                            href={bonPlan.subPlans && bonPlan.subPlans[bonPlanIndex].ctaMap}
                          >
                            Voir sur la carte
                          </ExternalLink>
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className={styles.illustrationsContainer}>
                    <div
                      className={styles.illustrations}
                      ref={illustrationsRef}
                      id="illustrations-caroussel"
                    >
                      {bonPlan.medias?.map((media) => (
                        <img src={media.url} alt={media.alt ?? ""} />
                      ))}
                    </div>

                    <CarousselWithLeftRightArrows containerRef={illustrationsRef} />
                  </div>
                </div>

                {bonPlan.practicalInformations && bonPlan.practicalInformations.length > 0 && (
                  <div className={styles.DropDowns}>
                    <Title className={classNames(styles.SubTitle, styles.InfosPratiques)}>
                      Informations pratiques
                    </Title>
                    {bonPlan.practicalInformations?.map((bloc) => (
                      <DropDown
                        title={bloc.title}
                        description={bloc.text}
                        htmlContent
                        id={`${idPracticalInformations[bloc.displayOrder]}-${
                          bonPlan.partner?.name
                        }`}
                        onClick={() => {
                          sendToDataLayer(
                            `${idPracticalInformations[bloc.displayOrder]}-${
                              bonPlan.partner?.name
                            }`,
                            false,
                          );
                        }}
                      />
                    ))}
                  </div>
                )}

                {bonPlan.reviews && bonPlan.reviews.length > 0 && (
                  <div className={styles.BonPlans__advicesContainer}>
                    <Title className={styles.SubTitle}>Avis de nos membres</Title>

                    <div className={styles.BonPlans__advices} ref={avisRef} id="avis-caroussel">
                      <MemberReview type="review-cta" className={styles.BonPlans__advice} />
                      {bonPlan.reviews?.map((review) => {
                        return (
                          <MemberReview
                            type="review"
                            review={review}
                            className={styles.BonPlans__advice}
                          />
                        );
                      })}
                    </div>
                    <CarousselWithLeftRightArrows containerRef={avisRef} />
                  </div>
                )}

                {bonPlan.relatedPlans && bonPlan.relatedPlans.length > 0 && (
                  <div className={styles.bonPlansAssociesContainer}>
                    <Title className={styles.SubTitle}>Bons plans associés</Title>
                    <div className={styles.bonPlansAssocies}>
                      {bonPlan.relatedPlans?.map((subPlan) => (
                        <div className={styles.bonPlanAssocie}>
                          <BonPlanCard bonPlan={subPlan} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export default BonPlan;
