import { FC, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RoutePaths } from "routes/routePaths";
import { ErrorBlock, ErrorBlockVariant } from "components/atoms/Error/ErrorBlock";

import useSmartPushFilters from "hooks/useSmartPush";

import styles from "./BonPlans.module.scss";
import { useRecoilValueLoadable } from "recoil";
import { useHealthCheckContext } from "HealthCheckProvider";
import { HealthCheckGroups } from "SignalRModels";
import BonsPlansCategory from "./components/BonPlanCategory/BonPlanCategory";
import BonPlanCard from "components/atoms/BonPlanCard/BonPlanCard";
import { classNames } from "utils/classNames";
import cssVariables from "styles/variables.module.scss";
import { Text, OverlayLoader } from "@roole/components-library";
import BonPlanToComeCard from "./components/BonPlanToComeCard/BonPlanToComeCard";
import { MemberReview } from "components/molecules/MemberReview/MemberReview";
import { Title } from "components/atoms/Title/Title";
import CarousselWithLeftRightArrows from "components/molecules/CarousselWithLeftRightArrows/CarousselWithLeftRightArrows";
import { ReactComponent as ArrowBack } from "images/icons/arrowBack.svg";
import {
  bonPlanByCategoryIdQuery,
  bonPlanCategoriesQuery,
  bonPlanHomepageQuery,
} from "context/bonPlansMobilitesState/selector";
import { BonPlanCategory, BonPlanMobilite } from "domain/api-schemas";
import { LoadableState } from "context/state.types";
import { PhoneNumber } from "components/atoms/PhoneNumber/PhoneNumber";
import { useMyFavoriteGarage } from "domain/service/MyGarage/useMyFavoriteGarage";

import useScreenSize from "hooks/useScreenSize";
import { useMatchMedia } from "hooks/useMatchMedia";
import { useTracking } from "hooks/useTracking";

const HomepageCategory = {
  id: "0",
  name: "Accueil",
  displayOrder: 0,
} as BonPlanCategory;

const BonsPlans: FC = () => {
  const { sendToDataLayer } = useTracking();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const categoryId = params.get("category") || "0";

  const bonsPlansState = useRecoilValueLoadable(bonPlanByCategoryIdQuery(categoryId));
  const bonsPlans =
    bonsPlansState.state === LoadableState.Has_Value
      ? [...(bonsPlansState.contents as BonPlanMobilite[])].sort(
          (a, b) => a.displayOrder - b.displayOrder,
        )
      : [];

  const categoriesState = useRecoilValueLoadable(bonPlanCategoriesQuery);
  const categories = useMemo(() => {
    return categoriesState.state === LoadableState.Has_Value
      ? [...(categoriesState.contents as BonPlanCategory[])].sort(
          (a, b) => a.displayOrder - b.displayOrder,
        )
      : [];
  }, [categoriesState]);

  const homepageState = useRecoilValueLoadable(bonPlanHomepageQuery);
  const homepage = homepageState.state === LoadableState.Has_Value ? homepageState.contents : null;

  const isLoading = [bonsPlansState, categoriesState, homepageState].some(
    (state) => state.state === LoadableState.Loading,
  );

  const hasError =
    bonsPlansState.state === LoadableState.Has_Error ||
    categoriesState.state === LoadableState.Has_Error ||
    homepageState.state === LoadableState.Has_Error;

  const currentCategory = categories?.find((c) => c.id === categoryId) || HomepageCategory;

  const { isMyGarageLoading, myDealership } = useMyFavoriteGarage();
  const { getHealthCheckByGroupName } = useHealthCheckContext();
  const [disableGroup, setDisableGroup] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const avisRef = useRef<HTMLDivElement | null>(null);
  const categoryContainerRef = useRef<HTMLDivElement>(null);

  const isDesktop = useMatchMedia(cssVariables.breakpointDesktop);
  const screenSize = useScreenSize();

  useEffect(() => {
    const child = document.getElementsByClassName(styles.BonPlans__Category_container)[0];

    if (!child) {
      return;
    }

    if (child.clientWidth < child.scrollWidth) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [screenSize]);

  useSmartPushFilters(
    ["ulys-4645", "total-wash-4646", "parc-attractions-4647", "cinema-4648", "cartes-cadeaux-4649"],
    true,
  );

  useEffect(() => {
    if (!currentCategory) {
      return;
    }
    const isRightCategory =
      currentCategory?.id === "0" ||
      categories?.findIndex((v: BonPlanCategory) => v.id === currentCategory?.id) !== -1;

    if (!isRightCategory && !isLoading) {
      navigate(RoutePaths.BON_PLANS_HOME);
    }
  }, [currentCategory, categories, isLoading, navigate]);

  useEffect(() => {
    setDisableGroup(getHealthCheckByGroupName(HealthCheckGroups.BonPLanNationaux));
  }, [getHealthCheckByGroupName]);

  useEffect(() => {
    if (!isLoading && currentCategory?.id !== categoryId) {
      navigate(RoutePaths.BON_PLANS_HOME);
    }
  }, [categoryId, currentCategory, isLoading, navigate]);

  const scrollCategories = (direction: "left" | "right") => {
    const categoryContainer = categoryContainerRef.current;
    if (categoryContainer) {
      const scrollAmount = 200;
      const currentScroll = categoryContainer.scrollLeft;
      categoryContainer.scrollTo({
        left: currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {disableGroup || hasError ? (
        <ErrorBlock variants={[ErrorBlockVariant.Chat]} />
      ) : (
        <>
          {isLoading && <OverlayLoader />}

          {!isLoading && (
            <div className={styles.BonPlans}>
              <div className={styles.TitleAndCategoriesWrapper}>
                <div className={styles.BonPlans__CategoriesWrapper}>
                  <Title className={styles.BonPlans__Title}>Mes bons plans</Title>
                  <div className={styles.BonPlans__Categories}>
                    {isScrollable && isDesktop && (
                      <div
                        className={classNames(styles.bonPlan_arrow, styles.bonPlan_arrow_left)}
                        onClick={() => scrollCategories("left")}
                      >
                        <ArrowBack width={20} height={20} />
                      </div>
                    )}
                    <div className={styles.BonPlans__Category_container} ref={categoryContainerRef}>
                      <BonsPlansCategory
                        category={{
                          id: "0",
                          name: "Accueil",
                          displayOrder: 0,
                        }}
                        currentSelected={currentCategory?.id}
                      />
                      {categories &&
                        categories.map((bonPlanCategory: BonPlanCategory) => (
                          <BonsPlansCategory
                            key={bonPlanCategory.id}
                            category={bonPlanCategory}
                            currentSelected={currentCategory?.id}
                          />
                        ))}
                    </div>
                    {isScrollable && isDesktop && (
                      <div
                        className={classNames(styles.bonPlan_arrow, styles.bonPlan_arrow_right)}
                        onClick={() => scrollCategories("right")}
                      >
                        <ArrowBack width={20} height={20} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {currentCategory !== null && (
                <>
                  {currentCategory?.id !== "0" ? (
                    <>
                      <div className={styles.BonPlansCards__Row}>
                        {bonsPlans?.map((bonPlan) => (
                          <BonPlanCard bonPlan={bonPlan} />
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.BonPlans__Importants}>
                        <div className={styles.BonPlans__Banniere}>
                          <BonPlanCard size="large" bonPlan={homepage?.mainPlan!} />
                        </div>

                        <div
                          className={classNames(
                            styles.BonPlansCards__Row,
                            styles.BonPlansCards__Row_twoElements,
                          )}
                        >
                          {homepage?.subMainPlans?.map((bonPlan) => (
                            <BonPlanCard bonPlan={bonPlan} />
                          ))}
                        </div>
                      </div>

                      {homepage?.trendingPlans && (
                        <>
                          <Title className={styles.BonPlans__SubTitle}>
                            Les bons plans du moment
                          </Title>

                          <div className={styles.BonPlansCards__Row}>
                            {homepage?.trendingPlans?.map((bonPlan) => (
                              <BonPlanCard bonPlan={bonPlan} />
                            ))}
                          </div>
                        </>
                      )}

                      {!isMyGarageLoading &&
                        myDealership?.mesBonsPlans &&
                        myDealership.mesBonsPlans.length > 0 && (
                          <>
                            <Title className={styles.BonPlans__SubTitle}>
                              Les bons plans de mon garage
                            </Title>

                            <div
                              id="bons-plans-mon-garage"
                              className={styles.BonPlansMonGarage}
                              onClick={(e) => {
                                sendToDataLayer(e.currentTarget.id, false);
                                navigate(RoutePaths.MON_COMPTE);
                              }}
                            >
                              <div className={styles.BonPlansMonGarageContact}>
                                <Text
                                  className="text--capitalize"
                                  textVariant="subheading-em"
                                  as="h6"
                                >
                                  {myDealership.nom}
                                </Text>
                                <Text textVariant="body">
                                  {myDealership.adresse}
                                  <br />
                                  {myDealership.cpVille}
                                  <br />
                                  <PhoneNumber
                                    className={styles.BonPlansMonGarageContactPhoneNumber}
                                    value={myDealership.telephone}
                                    useIcon={false}
                                  />
                                </Text>
                              </div>
                              <div className={styles.VoirBonPlansMonGarage}>
                                <Text textVariant="subheading-em">
                                  {`${myDealership.mesBonsPlans?.length ?? 0} ${
                                    (myDealership.mesBonsPlans?.length ?? 0) > 1
                                      ? "bons plans disponibles"
                                      : "bon plan disponible"
                                  } dans ce
                        garage`}
                                </Text>
                              </div>
                            </div>
                          </>
                        )}

                      {homepage?.upcomingPlans?.length! > 0 && (
                        <>
                          <Title className={styles.BonPlans__SubTitle}>
                            Les bons plans à venir
                          </Title>

                          <div className={styles.BonPlansAVenir}>
                            {homepage?.upcomingPlans?.map((bonPlan) => (
                              <BonPlanToComeCard bonPlan={bonPlan} />
                            ))}
                          </div>
                        </>
                      )}

                      {homepage?.reviews?.length! > 0 && (
                        <div className={styles.BonPlans__advicesContainer}>
                          <Title className={styles.BonPlans__SubTitle}>Avis de nos membres</Title>

                          <div
                            className={styles.BonPlans__advices}
                            ref={avisRef}
                            id="avis-caroussel"
                          >
                            <MemberReview type="review-cta" className={styles.BonPlans__advice} />
                            {homepage?.reviews?.map((review) => {
                              return (
                                <MemberReview
                                  type="review"
                                  review={review}
                                  className={styles.BonPlans__advice}
                                />
                              );
                            })}
                          </div>
                          <CarousselWithLeftRightArrows containerRef={avisRef} />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BonsPlans;
