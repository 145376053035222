import { ButtonV2, IconV2, Message, TextV2 } from "@roole/components-library";
import { FC, useCallback, useEffect } from "react";
import styles from "./OnBoarding.module.scss";
import { currentContratQuery } from "context/currentContratState/selector";
import { tunnelLayoutState, defaultStateValues } from "context/tunnelLayoutState/atom";
import { useNavigate } from "react-router-dom";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { RoutePaths } from "routes/routePaths";
import { Footer } from "components/molecules/Footer/Footer";

export const OnBoarding: FC = () => {
  const contrat = useRecoilValueLoadable(currentContratQuery);

  const setLayoutState = useSetRecoilState(tunnelLayoutState);
  const setupLayoutState = (setLayoutState: (state: any) => void, handleClose: () => void) => {
    setLayoutState({
      ...defaultStateValues,
      layoutId: "header-logo",
      title: "Déclarer un sinistre",
      closeButtonId: "declarer-sinistre-croix",
      onCloseCallback: handleClose,
    });
  };
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(RoutePaths.SINISTRES);
  }, [navigate]);

  useEffect(() => {
    setupLayoutState(setLayoutState, handleClose);
  }, [setLayoutState, handleClose]);

  return (
    <>
      <div className={styles.container}>
        <IconV2 name={"brake"} size={94} />
        <div>
          <TextV2 textVariant="heading-2" as="h2" className={styles.title}>
            Vous avez eu un sinistre ?
          </TextV2>
          <TextV2 textVariant="body-large" as="p">
            Roole vous accompagne et facilite votre déclaration pour être remboursé en un rien de
            temps. Sachez que vous pourrez faire appel à un conseiller, à tout moment.
          </TextV2>
        </div>
        <div className={styles.steps}>
          <TextV2 textVariant="heading-3" as="h3">
            Quelles sont les étapes à suivre ?
          </TextV2>
          <div className={styles.iconWithText}>
            <IconV2 name={"auto-shop"} color="neutral" />
            <TextV2 textVariant="body-medium">
              Vous nous racontez ce qu'il s'est passé (évènement, date, détail).
            </TextV2>
          </div>
          <div className={styles.iconWithText}>
            <IconV2 name={"shield"} color="neutral" />
            <TextV2 textVariant="body-medium">
              Nous vous proposons les garanties adéquates et nous vérifions ensemble si votre
              situation est bien éligible.
            </TextV2>
          </div>
          <div className={styles.iconWithText}>
            <IconV2 name={"file-text"} color="neutral" />
            <TextV2 textVariant="body-medium">
              Vous ajoutez les pièces justificatives lors de la déclaration ou plus tard depuis le
              suivi de votre sinistre.
            </TextV2>
          </div>
        </div>
        <Message
          icon="info"
          message={
            <TextV2 textVariant="body-small">
              <TextV2 textVariant="body-small-em">Important · </TextV2>Nous vous rappelons que les
              sinistres liés à un événement climatique (grêle, chute d'arbre ou de branche,
              inondations, etc.) ne sont pas couverts par Roole.
            </TextV2>
          }
          type={"warning"}
        />
        <div className={styles.button}>
          <ButtonV2
            variant="primary-brand"
            onClick={() => navigate(RoutePaths.DECLARER_SINISTRES_COMMENCER)}
          >
            Commencer ma déclaration
          </ButtonV2>
        </div>
        <div className={styles.urgence}>
          <div className={styles.iconWithText}>
            <IconV2 name={"zap-on"} color="neutral" />
            <TextV2 textVariant="body-medium" color="secondary">
              Vous avez une urgence ?
            </TextV2>
          </div>
          <div className={styles.contact}>
            <TextV2 textVariant="body-medium" color="secondary">
              Contactez-nous
            </TextV2>
            <TextV2 textVariant="body-medium-em" color="secondary">
              · 01 46 90 37 00
            </TextV2>
          </div>
        </div>
      </div>
      <Footer contrat={contrat?.contents || null} idAdhesion={null} />
    </>
  );
};
