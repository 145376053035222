import axiosClient from "clientProvider/axiosConfig";

export enum FeaturesToggle {
  Sinistre = "CP.Sinistre.SelfCare",
}

export const fetchToggle = async (toggleName: string) => {
  if (!Object.values(FeaturesToggle).includes(toggleName as FeaturesToggle)) {
    return false;
  }
  const result = await axiosClient.get<boolean>(`FeatureFlag/IsEnable/${toggleName}`);
  return result.data;
};
